<template>
  <label class="item">
    <div class="item-content">
      <img
        class="item-image"
        :src="imageUrl"
        role="presentation"
        loading="lazy"
        :alt="altText"
      />
      <div class="item-body">
        <div class="item-name">{{ quantity }} x {{ name }}</div>
      </div>
    </div>
  </label>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    props: {
      inSidebar: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      quantity() {
        return this.getValue("quantity")
      },
      name() {
        return this.variant.variantName
      },
      variantId() {
        return this.getValue("variant")
      },
      imageUrl() {
        return this.variant.imageUrl
      },
      altText() {
        return this.variant.imageAlt
      },
      variant() {
        return this.getIngredient("variant") || {}
      },
    },
  }
</script>

<style lang="scss" scoped>
  .item {
    display: flex;
    justify-content: space-between;
    &.item-in-sidebar {
      align-items: center;
      padding-bottom: $base-spacing * 4;
      border-bottom: 1px solid $silver-500;
      margin-bottom: $base-spacing * 4;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .item-content {
    position: relative;

    transition: opacity 120ms ease-in-out;
    display: flex;
  }

  .item-image {
    position: relative;

    display: block;
    flex-shrink: 0;
    width: $base-spacing * 10;
    height: $base-spacing * 10;
    border-radius: $border-radius;
    object-fit: cover;
    margin-right: $base-spacing * 4;
    box-shadow: $shadow;
    &:before {
      background: $gray;
    }
  }

  .item-name {
    font-weight: bold;
  }
  .item-info {
    color: $gray-text;
  }
  /* Checkbox */
  .checkbox {
    opacity: 0;
    position: absolute;
    &:focus ~ .bogus-checkbox {
      box-shadow: 0 0 0px 2px $onyx;
    }
    &:checked ~ {
      .bogus-checkbox {
        background: $blue-700;
        border-color: $blue-700;

        .check-icon {
          opacity: 1;
        }
      }
    }
  }
  .bogus-checkbox {
    margin-right: $base-spacing * 2;
    border-radius: 4px;
    width: $base-spacing * 4;
    height: $base-spacing * 4;
    border: 2px solid $gray-200;
    transition: 120ms ease-in-out all;

    @media print {
      display: none;
    }
  }
  .check-icon {
    color: white;
    transition: 200ms ease-in-out all;
    opacity: 0;
    width: $base-spacing * 4;
    height: $base-spacing * 4;
  }
</style>
